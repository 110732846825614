import {
  TEXT,
  EMAIL,
  PHONE,
  NUMBER,
  VALIDATION_ERRORS,
  STRING,
  REQUIRED,
} from "Constants";

const isValidPhone = (value) => {
  const re = /^\+[1-9]\d{10,14}$/;
  const phone_number = value.replace(/\s/g, "");
  return re.test(phone_number);
};
const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const isValidNumber = (number) => {
  const re = /^[0-9]*\d$/;
  return re.test(number);
};

export const isValidForm = ({ text, validation, setFieldError }) => {
  let isValid = true;
  Object.keys(text).forEach((key) => {
    const field = text[key];
    const isRequired = validation[key].includes(REQUIRED);
    const isPhoneNumber = validation[key].includes(PHONE);
    const isEmail = validation[key].includes(EMAIL);
    const isNumber = validation[key].includes(NUMBER);
    if (!field?.length && isRequired && validation[key].includes(STRING)) {
      isValid = false;
      setFieldError((prev) => ({
        ...prev,
        [key]: VALIDATION_ERRORS.required,
      }));
    } else if (isPhoneNumber && !isValidPhone(field)) {
      isValid = false;
      setFieldError((prev) => ({
        ...prev,
        [key]: VALIDATION_ERRORS.phone,
      }));
    } else if (isEmail && !isValidEmail(field)) {
      isValid = false;
      setFieldError((prev) => ({
        ...prev,
        [key]: VALIDATION_ERRORS.email,
      }));
    } else if (isNumber && !isValidNumber(field)) {
      isValid = false;
      setFieldError((prev) => ({
        ...prev,
        [key]: VALIDATION_ERRORS.number,
      }));
    }
  });
  return isValid;
};

export const isValidField = ({ value, type = TEXT, required = false }) => {
  if (required && !value?.length) return true;
  if (type === EMAIL) {
    return !isValidEmail(value);
  }
  if (type === PHONE) {
    return !isValidPhone(value);
  }

  if (type === NUMBER) {
    return !isValidNumber(value);
  }
};

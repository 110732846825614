import React, { useState } from "react";
import { WrapperSeamless } from "layout-components";
import Table from "components/tables/table";
import { Button } from "@material-ui/core";
import { useGetAllList, usePostData } from "hooks";
import { Link } from "react-router-dom";
import * as Api from "service";
import useStore from "context";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "localization/translation";


import { MANAGER_ROLE, DRAG_ALT, ORDER, ADD_ONE_TO_ORDER } from "Constants";

import dragIcon from "assets/icons/dragIcon.svg";

const query = {
  sort: "created_at",
  order: "desc",
};

export default function HomeClients() {
  const { setRemove, language, user } = useStore();
  const { setErrorMsg } = useStore();
  const translation = useTranslation();
  const [activateDrag, setActivateDrag] = useState(false);
  const [dragElement, setDragElement] = useState(null);

  const apiCall =
    user.role === MANAGER_ROLE ? Api.goodsTypeManager : Api.goodsType;

  const [items, total, refresh, setItems] = useGetAllList(apiCall.getAll);

  const orderCallback = usePostData(
    Api.goodsTypeOrder.create,
    refresh,
    ORDER
  );

  const dragEnd = () => {
    setActivateDrag(false);
    setDragElement(null);
    const newOrderItems = items.map((value, index) => {
      return { id: value.id, order: index };
    });
    orderCallback({ data: newOrderItems });
  };

  const rows = items?.map((item, index) => (
    <>
      <td className={`text-left px-4 ${activateDrag && "background_unset"}`}>
        <div className="font-size-sm">
          <span className="drag-icon-field">
            <span
              className="drag-icon-background"
              onMouseDown={() => {
                setActivateDrag(true);
                setDragElement(item.id);
              }}
              onMouseUp={() => {
                setActivateDrag(false);
                setDragElement(null);
              }}
            ></span>
            <img
              src={dragIcon}
              alt={DRAG_ALT}
              className="drag-icon"
              id="dragIcon"
            />
          </span>
          {item[`name_${language}`]}
        </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm px-3">
          {item.order !== null
            ? Number(item.order) + ADD_ONE_TO_ORDER
            : index + ADD_ONE_TO_ORDER}
        </div>
      </td>
      <td className={`text-right ${activateDrag && "background_unset"}`}>
        <Link to={`/dashboard/goods-type/create/${item.id}`}>
          <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <EditIcon color="primary" />
          </Button>
        </Link>

        <Button
          onClick={() => {
            setRemove(() => () =>
              apiCall
                .delete(item.id)
                .then(refresh)
                .catch((err) => {
                  setErrorMsg(err.message);
                })
            );
          }}
          className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
        >
          <DeleteIcon color="danger" />
        </Button>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        title={translation.goods_types}
        items={items}
        setItems={setItems}
        total={total}
        fields={
          <>
            <th className="text-left px-4">
              <span className="mr-3 center-filed">{translation.name}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.order}</span>
            </th>
          </>
        }
        rows={rows}
        createLink="dashboard/goods-type/create"
        limit={items?.length}
        orderCallback={orderCallback}
        activateDrag={activateDrag}
        dragElement={dragElement}
        dragEnd={dragEnd}
      />
    </WrapperSeamless>
  );
}

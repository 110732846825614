import React, {useEffect, useState} from "react";
import {useParams, useHistory} from "react-router-dom";
import {useGetData } from "hooks";
import * as Api from "service";
import {WrapperSeamless} from "layout-components";
import {Button, Card, CardContent, Tooltip} from "@material-ui/core";
import ViewIcon from "@material-ui/icons/Visibility";
import HistoryModal from "./HistoryModal";
import { useTranslation } from 'localization/translation';
import {getQueryParam} from "helpers/getQueryParam";
import { inventoryDateFormat } from "helpers/inventoryDateFormat";

const ViewItems = () => {
    const translation = useTranslation();
    const {id, barcode, did } = useParams();
    const [historyId, setHistoryId] = useState(null);
    const [items] = useGetData(
        Api.inventories.getById, id + '/' + barcode
    );

    const history = useHistory();

    const handleRoute = (id) => {
        history.push({
            search: `?id=${id}`,
        })
    };

    const closeHandler = () => {
        history.goBack()
    }

    useEffect(() => {
        setHistoryId(getQueryParam(history.location, 'id'))
    }, [history.location.search]);


    return (
        <WrapperSeamless>
            <Card className="card-box mb-spacing-6-x2">
                <CardContent>
                    <h6 className="font-weight-bold font-size-lg mb-0 text-black">
                        Inventory #{did}
                        <hr/>
                        Total left over {items?.left_over.toFixed(2)}
                    </h6>
                    <hr/>
                    <table className="table">
                        <thead className="text-center">
                            <th>
                                {translation.barcode}
                            </th>
                            <th>
                                {translation.full_bottle}
                            </th>
                            <th>
                                {translation.bottle_count}
                            </th>
                            <th>
                                {translation.left_over_ml}
                            </th>
                            <th>
                                {translation.status}
                            </th>
                            <th>
                                {translation.created_at}
                            </th>
                            <th>
                                {translation.updated_at}
                            </th>
                            <th>
                                {translation.deleted_at}
                            </th>
                            <th>
                                {translation.action}
                            </th>
                        </thead>
                        <tbody className="text-center">
                        {items?.data?.map((item) => {
                            return (
                                <tr key={item?.id}>
                                    <td>
                                        {item?.barcode}
                                    </td>
                                    <td>
                                        {item?.is_full ? 'Full' : 'Open'}
                                    </td>
                                    <td>
                                        {item?.count}
                                    </td>
                                    <td>
                                        {item?.total_liters.toFixed(2)}
                                    </td>
                                    <td>
                                        {!!item?.added_after_completed ? <span className='blue_status'>{item?.status}</span> : item?.status}
                                    </td>
                                    <td>
                                        {item?.created_at && inventoryDateFormat(item?.created_at)}
                                    </td>
                                    <td>
                                        {item?.updated_at && inventoryDateFormat(item?.updated_at)}
                                    </td>
                                    <td>
                                        {item?.deleted_at && inventoryDateFormat(item?.deleted_at)}
                                    </td>
                                    <td>
                                        {['Edited','Deleted'].includes(item?.status) && (
                                            <Tooltip placement={`top`} title={`View history`}>
                                                <Button onClick={() => handleRoute(item?.id)} className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                                                    <ViewIcon color="primary" />
                                                </Button>
                                            </Tooltip>)}

                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </CardContent>
            </Card>
            {historyId && <HistoryModal open={historyId} closeHandler={closeHandler} />}
        </WrapperSeamless>
    )
}

export default ViewItems;

import React, { useCallback } from 'react';
import { Container, TextField, Card, Box } from '@material-ui/core';

import {
  useText,
  useGetData,
  usePutData,
  usePostData, useGetList
} from '../../../hooks';
import useStore from '../../../context';

import * as Api from '../../../service';
import { Save, MultiSelect } from '../../../components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'localization/translation';

const initForm = {
  id: '',
  name: '',
  surname: '',
  phone: '',
  email: '',
  branches: [],
};

const Form = () => {
  const { id } = useParams();
  const translation = useTranslation();
  const [data, , refresh] = useGetData(
    id && Api.managerInvitations.getById,
    id
  );
  const postCallback = usePostData(Api.managerInvitations.create, refresh);
  const updateCallback = usePutData(Api.managerInvitations.update, refresh);
  const { setErrorMsg } = useStore();

  const [text, setText, , input] = useText(data || initForm);
  const [ branches ] = useGetList(Api.branches.getAll);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      id ? updateCallback(text) : postCallback(text);
    },
    [id, postCallback, setErrorMsg, text, updateCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <TextField {...input('name')} label={translation.first_name} />
          <TextField {...input('surname')} label={translation.last_name} />
          <TextField {...input('phone')} label={translation.phone} />
          <TextField {...input('email')} label={translation.email} />
          {
            branches && <>
              <MultiSelect
                  noCard
                  className='w-100'
                  value={text.branches}
                  onChange={val => setText(st => ({ ...st, branches: val }))}
                  data={branches}
                  label={translation.select_branch}
                  field="name"
              />
            </>
          }
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;

import React, { useState } from 'react';
import clsx from 'clsx';
import { Collapse } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone';
import Menu from '../../menu.js'
import useStore from '../../context';
import goodsLogo from '../../assets/images/wine.svg';
import goodTypesLogo from "../../assets/images/supermarket.svg";
import BranchManagerLogo from "../../assets/images/branch-manager.svg";
import ManagerInvitationsLogo from "../../assets/images/manager-invitation.svg";
import ManagersLogo from "../../assets/images/manager.svg";
import BarmansListLogo from "../../assets/images/barman.svg";
import InventoriesLogo from "../../assets/images/inventory.svg";
import { useTranslation } from 'localization/translation';


const SidebarMenu = (props) => {
  const { setSidebarToggleMobile } = props;
  const [menuOpen, setMenuOpen] = useState(null);
  const { user } = useStore();
  const translation = useTranslation();

  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>{translation.navigation_menu}</span>
          </div>
          <ul>
            {user?.role === 'customer' && Object.keys(Menu).map((el, index) =>
              <li key={index}>
                {Array.isArray(Menu[el]) ?
                  <>
                    <a
                      href="#/"
                      onClick={(e) => { e.preventDefault(); setMenuOpen(st => st === index ? null : index) }}
                      className={clsx({ active: menuOpen === index })}>
                      <span className="sidebar-icon">
                        {el === "Goods" ? <img src={goodsLogo} alt="goods" /> :
                            el === "Goods type" ? <img src={goodTypesLogo} alt="good types" /> :
                                el === "Branch management" ? <img src={BranchManagerLogo} alt="Branch management" /> :
                                    el === "Manager invitations" ? <img src={ManagerInvitationsLogo} alt="Manager invitations" /> :
                                        el === "Managers" ? <img src={ManagersLogo} alt="Managers" /> :
                                            el === "Barmen list" ? <img src={BarmansListLogo} alt="Barmen list" /> :
                                              el === "Inventories" ? <img src={InventoriesLogo} alt="inventories" /> :
                                                <VerifiedUserTwoToneIcon />}
                      </span>
                      <span style={{
                        textTransform: 'capitalize'
                      }} className="sidebar-item-label">
                        {translation[`${el.replaceAll(' ', '_').toLowerCase()}`]}
                      </span>
                      <span className="sidebar-icon-indicator">
                        <ChevronRightTwoToneIcon />
                      </span>
                    </a>
                    <Collapse in={menuOpen === index}>
                      <ul>
                        {Menu[el].map((e,i) =>
                          <li key={i}>
                            <NavLink
                              exact
                              onClick={() => setSidebarToggleMobile(false)}
                              to={e.link}>
                              {translation[`${e.name.replaceAll(' ', '_').toLowerCase()}`]}
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </Collapse>
                  </> : <NavLink
                    to={Menu[el]}
                    onClick={() => { setSidebarToggleMobile(false); setMenuOpen(null) }}>
                    <span className="sidebar-icon">
                      {el === "Goods" ? <img src={goodsLogo} alt="goods" /> :
                          el === "Goods type" ? <img src={goodTypesLogo} alt="good types" /> :
                              el === "Branch management" ? <img src={BranchManagerLogo} alt="Branch management" /> :
                                el === "Manager invitations" ? <img src={ManagerInvitationsLogo} alt="Manager invitations" /> :
                                  el === "Managers" ? <img src={ManagersLogo} alt="Managers" /> :
                                    el === "Barmen list" ? <img src={BarmansListLogo} alt="Barmen list" /> :
                                        el === "Inventories" ? <img src={InventoriesLogo} alt="Inventories" /> :
                                  <VerifiedUserTwoToneIcon />}
                    </span>
                    <span className="sidebar-item-label">
                      {translation[`${el.replaceAll(' ', '_').toLowerCase()}`]}
                    </span>
                  </NavLink>
                }
              </li>
            )}

            {user?.role === 'manager' && <li>
              <NavLink
                  to="/dashboard/barmen"
                  onClick={() => { setSidebarToggleMobile(false); setMenuOpen(null) }}>
                    <span className="sidebar-icon">
                      <img src={BarmansListLogo} />
                    </span>
                <span className="sidebar-item-label">{translation.barmen_list}</span>
              </NavLink>
              <NavLink
                  to="/dashboard/inventories"
                  onClick={() => { setSidebarToggleMobile(false); setMenuOpen(null) }}>
                    <span className="sidebar-icon">
                      <img src={InventoriesLogo} alt="inventories" />
                    </span>
                <span className="sidebar-item-label">{translation.inventories}</span>
              </NavLink>
              <NavLink
                  to="/dashboard/goods-type"
                  onClick={() => { setSidebarToggleMobile(false); setMenuOpen(null) }}>
                    <span className="sidebar-icon">
                      <img src={goodTypesLogo} alt="goods type" />
                    </span>
                <span className="sidebar-item-label">{translation.goods_type}</span>
              </NavLink>
              <NavLink
                  to="/dashboard/goods"
                  onClick={() => { setSidebarToggleMobile(false); setMenuOpen(null) }}>
                    <span className="sidebar-icon">
                      <img src={goodsLogo} alt="goods" />
                    </span>
                <span className="sidebar-item-label">{translation.goods}</span>
              </NavLink>
            </li>}
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);

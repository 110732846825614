import React from 'react';
import './styles.scss';

const Input = ({
 type = 'text',
 placeholder,
 multiple,
 autoComplete,
 name,
 value,
 onChange,
 required = false,
 pattern,
 title,
 accept,
}) => (
  <input
    multiple={multiple}
    required={required}
    className="input"
    name={name}
    value={value}
    onChange={onChange}
    type={type}
    placeholder={placeholder}
    autoComplete={autoComplete}
    pattern={pattern}
    title={title}
    accept={accept}
  />
);
export default Input;

import React from 'react';
import styles from './styles.module.scss'
import { ReactComponent as IMac} from '../../assets/images/iMac.svg';
import {Link} from "react-router-dom";

const LoginBackground = () => {
  return (
    <div className={styles.bgSection}>
      <h1>
        <Link to="/">Bar control</Link>
      </h1>
      <div className={styles.container}>
        <IMac />
        <div className={styles.copyrightWrapper}>
          <p className={styles.copyright}>© 2022. All Rights Reserved</p>
        </div>
    </div>
    </div>
  )
};

export default LoginBackground;

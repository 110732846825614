import { useState, useEffect, useCallback } from "react";

export const useGetAllList = (Api) => {
  const [items, setItems] = useState();
  const [total, setTotal] = useState(null);

  const callback = useCallback(() => {
    if (Api) {
      Api()
        .then((res) => {
          setItems(res.data);
          setTotal(res.total);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [Api]);

  useEffect(callback, [Api]);

  return [items, total, callback, setItems];
};

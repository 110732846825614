import React, { useCallback } from "react";
import { Container, TextField, Card, Box } from "@material-ui/core";

import { useText, useGetData, usePutData, usePostData } from "hooks";
import useStore from "context";

import * as Api from "service";
import { Lang, Save } from "components";
import { useParams } from "react-router-dom";
import { useTranslation } from "localization/translation";
import { MANAGER_ROLE, ERROR_MESSAGE } from "../constants";
import { NAME_EN, NAME_HY, NAME_RU } from "Constants";

const initForm = {
  name_en: "",
  name_hy: "",
  name_ru: "",
};

const Form = () => {
  const { id } = useParams();
  const { setErrorMsg, lang, user } = useStore();
  const translation = useTranslation();

  const apiCall =
    user.role === MANAGER_ROLE ? Api.goodsTypeManager : Api.goodsType;

  const [data, , refresh] = useGetData(id && apiCall.getById, id);
  const postCallback = usePostData(apiCall.create, refresh);
  const updateCallback = usePutData(apiCall.update, refresh);

  const [text, , , input] = useText(data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      for (const [key, value] of Object.entries(text)) {
        if (
          (key === NAME_EN || key === NAME_HY || key === NAME_RU) &&
          !value
        ) {
          return setErrorMsg(ERROR_MESSAGE);
        }
      }
      id ? updateCallback(text) : postCallback(text);
    },
    [id, postCallback, setErrorMsg, text, updateCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />

          <TextField {...input(`name_${lang}`)} label={translation.name} />

          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;

import React, { useCallback, useEffect, useState } from 'react';
import { WrapperSeamless } from '../../../layout-components';
import Table from '../../../components/tables/table';
import { Button } from '@material-ui/core';
import { useGetList } from '../../../hooks';
import { Select } from '../../../components'
import { Link, useLocation } from 'react-router-dom';
import * as Api from '../../../service';
import useStore from '../../../context';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'localization/translation';

const noLimit = { limit: 0 }
export default function HomeClients() {
  const { setRemove, language, user } = useStore();
  const translation = useTranslation();
  const [typesArray,setTypesArray] = useState();
  const [query, setQuery] = useState({
    sort: 'created_at',
    order: 'desc',
    type: '',
  });

  const limit = 20;

  const apiCall = user.role === "manager" ? Api.goodsManager : Api.goods;
  const apiCallTypes = user.role === "manager" ? Api.goodsTypeManager : Api.goodsType;

  const [types] = useGetList(apiCallTypes.getAll, noLimit);
  const [items, total, refresh, setItems] = useGetList(
    apiCall.getAll,
    query,
    limit
  );

  const queryUrl = new URLSearchParams(useLocation());

  const callback = useCallback(() => {
     if(apiCall.getAll){
      apiCall.getAll({
        page: (queryUrl.get('page') || '1') - 1,
        limit: total
      })
      .then(res => setTypesArray(res.data))
    }
  })

  useEffect(callback,[query]);

  const rows = items?.map((item) => (
    <>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.barcode}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item[`name_${language}`]}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.weight_full}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.weight_empty}</div>
      </td>
      <td className="text-right">
        <Link to={`/dashboard/goods/create/${item.id}`}>
          <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <EditIcon color="primary" />
          </Button>
        </Link>

        <Button
          onClick={() =>
            setRemove(() => () =>
            apiCall.delete(item.id).then(refresh)
            )
          }
          className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
          <DeleteIcon color="danger" />
        </Button>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        noDrag
        title={translation.goods}
        items={items}
        setItems={setItems}
        total={total}
        fields={
          <>
            <th className="text-left px-4">
              <span className="mr-3">{translation.barcode}</span>
            </th>
           <th className="text-left px-4">
              <span className="mr-3">{translation.name}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.weight_full}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.weight_empty}</span>
            </th>
          </>
        }
        header={
          types && <Select
            onChange={val => {
              setQuery(st => ({...st, type: val}))
            }}
            value={query.type}
            field={`name_${language}`}
            label={translation.good_type}
            noCard
            options={types.length !== 0 ? types : typesArray}
            className='ml-4 mr-auto w-25'
          />
        }
        rows={rows}
        createLink="dashboard/goods/create"
        limit={limit}
      />
    </WrapperSeamless>
  );
}

import { useState, useEffect, useCallback } from 'react'

export const useGetData = (Api, params, search) => {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState()

  const callback = useCallback(() => {
    if (Api) {
      setLoading(true)
      Api(params, {search}).then(res => {
        setState(res)
      })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [Api, params, search]);

  useEffect(callback, [Api, params, search])

  return [state, loading, callback, setState]
}
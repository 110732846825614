import React, { useState } from "react";
import { WrapperSeamless } from "../../../layout-components";
import Table from "../../../components/tables/table";
import { Button, Tooltip } from "@material-ui/core";
import { useGetList } from "../../../hooks";
import { Select } from "../../../components";
import { Link } from "react-router-dom";
import * as Api from "../../../service";
import useStore from "../../../context";
import DeleteIcon from "@material-ui/icons/Delete";
import ViewIcon from "@material-ui/icons/Visibility";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import ListAlt from "@material-ui/icons/ListAlt";
import moment from "moment";
import "./list.scss";
import Base from "../../../service/base";
import { useTranslation } from 'localization/translation';

const noLimit = { limit: 0 };
export default function HomeClients() {
  const translation = useTranslation();
  const { setRemove, lang, user } = useStore();
  const [query, setQuery] = useState({
    sort: "created_at",
    order: "desc",
    branch: "",
    search: "",
  });
  const [branches] = useGetList(Api.branches.getAll, noLimit);

  const [items, total, refresh, setItems] = useGetList(
    Api.inventories.getAll,
    query
  );

  const exportExcel = (id) => {
    const api = new Base();
    api.getRequest("/export/excel/" + id).then((res) => {
      const downloader = document.getElementById("downloader");
      downloader.href = res.path;
      downloader.click();
    });
  };

  const exportPdf = (id) => {
    const api = new Base();
    api.getRequest("/export/pdf/" + id).then((res) => {
      const downloader = document.getElementById("downloader");
      downloader.href = res.path;
      setTimeout(() => {
        downloader.click();
      }, 2000);
    });
  };

  const rows = items?.map((item) => (
    <>
      <td className="text-left px-4">
        <div className="font-size-sm">Inventory #{item?.row_number}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item?.branch?.name}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">
          {item?.status === "open" ? "In progress" : "Complete"}
        </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">
          {moment(item?.created_at).format("H:m MM/DD/YYYY")}
        </div>
      </td>

      <td className="text-right">
        { user.role === 'customer' &&
          <Button
            onClick={() =>
              setRemove(() => () => Api.inventories.delete(item?.id).then(refresh))
            }
            className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
          >
            <DeleteIcon color="danger"/>
          </Button>
        }
        <Tooltip placement={`top`} title={translation.view_details}>
          <Link to={`/dashboard/inventories/${item?.id}/${item?.row_number}`}>
            <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
              <ViewIcon color="primary" />
            </Button>
          </Link>
        </Tooltip>
        <Tooltip placement={`top`} title={translation.export_pdf}>
          <Button
            onClick={() => exportPdf(item?.id)}
            className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
          >
            <PictureAsPdf color="primary" />
          </Button>
        </Tooltip>
        <Tooltip placement={`top`} title={translation.export_excel}>
          <Button
            onClick={() => exportExcel(item?.id)}
            className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
          >
            <ListAlt color="primary" />
          </Button>
        </Tooltip>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        noCreateBtn
        noDrag
        title={translation.inventories}
        items={items}
        setItems={setItems}
        total={total}
        fields={
          <>
            <th className="text-left px-4">
              <span className="mr-3">{translation.name}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">
                {translation.branch_name}
              </span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.status}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">
                {translation.created}
              </span>
            </th>
          </>
        }
        header={
          <div className="header-filters">
            <Select
              onChange={(val) => setQuery((st) => ({ ...st, status: val }))}
              value={query.status}
              field={`name_${lang}`}
              label={translation.status}
              noCard
              options={[
                {
                  id: "open",
                  title_en: `${translation.in_progress}`,
                },
                {
                  id: "closed",
                  title_en: `${translation.complete}`,
                },
              ]}
              className="ml-4 w-25"
            />
            {branches && (
              <Select
                onChange={(val) => setQuery((st) => ({ ...st, branch: val }))}
                value={query.branch}
                field={`name`}
                label={translation.branch}
                noCard
                options={branches}
                className="ml-4 w-25"
              />
            )}
            <Select
              onChange={(val) => setQuery((st) => ({ ...st, order: val }))}
              value={query.order}
              field={`name`}
              label={translation.order}
              noCard
              options={[
                { id: "desc", name: `${translation.newest}` },
                { id: "asc", name: `${translation.oldest}` },
              ]}
              className="ml-4 w-25"
            />
          </div>
        }
        rows={rows}
        createLink="dashboard/goods/create"
      />
      <a
        style={{ display: "none" }}
        href={`/`}
        id={`downloader`}
        target={`_blank`}
        download
      >
        Download
      </a>
    </WrapperSeamless>
  );
}

import React, { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Container,
  TextField,
  Card,
  Box,
  InputAdornment,
} from "@material-ui/core";
import { AutorenewTwoTone } from "@material-ui/icons";

import {
  useText,
  useGetData,
  usePutData,
  useGetList,
  usePostData,
} from "hooks";
import useStore from "context";
import * as Api from "service";

import { Lang, Save, Select } from "components";
import { calculate } from "helpers/calculateGoods";
import Gallery from "components/gallery";
import { useTranslation } from "localization/translation";

import { ERROR_MESSAGE, MANAGER_ROLE } from "../constants";

const initForm = {
  name_en: "",
  name_hy: "",
  name_ru: "",
  goods_type_id: "",
  weight_full: "",
  weight_empty: "",
  barcode: "",
  litrage: "",
  image: "",
};
const query = { limit: 0 };
const Form = () => {
  const { id } = useParams();
  const translation = useTranslation();
  const { setErrorMsg, lang, user } = useStore();
  const [newFiles, setNewFiles] = useState(null);
  const [labelName, setLabelName] = useState(translation.details);
  const [density, setDensity] = useState();
  const [isDisable, setIsDisable] = useState(false);

  const apiCallTypes =
    user.role === MANAGER_ROLE ? Api.goodsTypeManager : Api.goodsType;
  const apiCall = user.role === MANAGER_ROLE ? Api.goodsManager : Api.goods;

  const [types] = useGetList(apiCallTypes.getAll, query);
  const [data, , refresh] = useGetData(id && apiCall.getById, id);
  const postCallback = usePostData(apiCall.create, refresh);
  const updateCallback = usePutData(apiCall.update, refresh);

  const [text, setText, , input] = useText(data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      if (["hy", "ru", "en"].some((el) => !text[`name_${el}`]))
        return setErrorMsg(ERROR_MESSAGE);

      const formData = new FormData();
      if (newFiles) {
        formData.append("file", newFiles[0]);
      }

      const link = newFiles
        ? await Api.main.uploadFile(formData).then((res) => res.filePath)
        : text.image;

      const data = {
        ...text,
        image: link,
      };

      id ? updateCallback(data) : postCallback(data);
    },
    [id, postCallback, setErrorMsg, text, updateCallback, newFiles]
  );

  const handleSetFullWeight = () => {
    const full = calculate.fullWeight(text.weight_empty, text.litrage, density);
    setText((st) => ({ ...st, weight_full: full }));
  };

  const handleSetEmptyWeight = () => {
    const empty = calculate.emptyWeight(
      text.weight_full,
      text.litrage,
      density
    );
    setText((st) => ({ ...st, weight_empty: empty }));
  };

  const handleChangeGoodType = (id) => {
    const findGood = types.find((elem) => elem.id === id);
    const averageDensity = Number(findGood?.average_density);
    setDensity(averageDensity);
    setText((st) => ({ ...st, goods_type_id: id }));
  };

  useEffect(() => {
    if (data && types) {
      const findGood = types.find((elem) => elem.id === data.goods_type_id);
      const averageDensity = Number(findGood?.average_density);
      setDensity(averageDensity);
    }
  }, [data, types]);

  useEffect(() => {
    const equal = calculate.isEqual(
      text.weight_full,
      text.weight_empty,
      text.litrage,
      density
    );
    setIsDisable(equal);
  }, [text, density]);

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <Gallery
            single
            label={labelName}
            setNewFile={setNewFiles}
            value={text.image ? text.image : null}
            setImages={(img) => setText((st) => ({ ...st, image: img }))}
            onChange={() => setText((st) => ({ ...st, image: "" }))}
            setLabelName={setLabelName}
          />
          <TextField {...input(`name_${lang}`)} label={translation.title} />
          {types && (
            <Select
              onChange={(val) => handleChangeGoodType(val)}
              value={text.goods_type_id}
              field={`name_${lang}`}
              density="average_density"
              label={translation.goods_type}
              noCard
              options={types}
            />
          )}
          <TextField {...input(`barcode`)} label={translation.barcode} />
          <TextField
            {...input(`weight_full`)}
            type="number"
            label={translation.full_bottle_weight}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <button
                    className="unstyle_button"
                    type="button"
                    disabled={
                      isDisable ||
                      (text?.weight_empty && text?.litrage && !!density
                        ? false
                        : true)
                    }
                    onClick={handleSetFullWeight}
                  >
                    <AutorenewTwoTone />
                  </button>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            {...input(`weight_empty`)}
            type="number"
            label={translation.empty_bottle_weight}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <button
                    className="unstyle_button"
                    type="button"
                    disabled={
                      isDisable ||
                      (text?.weight_full && text?.litrage && !!density
                        ? false
                        : true)
                    }
                    onClick={handleSetEmptyWeight}
                  >
                    <AutorenewTwoTone />
                  </button>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            {...input(`litrage`)}
            type="number"
            label={translation.volume_milliliter}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;

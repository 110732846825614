import React, {useCallback, useEffect, useState} from 'react';
import { Container, TextField, Card, Box } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  useText,
  useGetData,
  usePutData,
  usePostData
} from '../../../hooks';
import useStore from '../../../context';

import * as Api from '../../../service';
import { Save } from '../../../components';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { useTranslation } from 'localization/translation';

import { OPEN_STREET_MAP_URL } from '../constants';

const initForm = {
  name: '',
  phone: '',
  address: '',
  longitude: 0,
  latitude: 0,
};

const Form = () => {
  const translation = useTranslation();
  const { id } = useParams();
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const [data, , refresh] = useGetData(
    id && Api.branches.getById,
    id
  );
  const postCallback = usePostData(Api.branches.create, refresh);
  const updateCallback = usePutData(Api.branches.update, refresh);
  const { setErrorMsg } = useStore();

  const [text,setText , , input, error, setError] = useText(data || initForm);
  const phoneValidator = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

  useEffect(() => {
    if(data) setValue(data?.address)
  }, [data])

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      setError({});
      if(phoneValidator.test(text.phone) && text.latitude) {
        id ? updateCallback(text) : postCallback(text);
      } else {

        setError({
            phone: !phoneValidator.test(text.phone),
            address: !text.latitude,
        });
        setErrorMsg("Fields are invalid.")
      }
    },
    [id, postCallback, setErrorMsg, text, updateCallback]
  );

  const searchPlace = (e) => {
    if(e) setValue(e?.target?.value ?? "");
  };

  const setBranchInitialData = (value) => {
    setValue(value ? value?.display_name : '')
    const location = {
      latitude: value?.lat ?? 0,
      longitude: value?.lon ?? 0,
      address: value?.display_name,
    };
    setText((prev) => ({
      ...prev,
      ...location
    }));
  };

  useEffect(() => {
    const timeOutId = setTimeout(async () => {
      if (value) {
        const url = `${OPEN_STREET_MAP_URL}search?q=${value}&countrycodes=am,ae&addressdetails=1&format=json`;
        const res = await axios.get(url);
        const optionsData = res.data.map((option) => ({
          label: option.display_name,
          value: option
        }))
        setOptions(optionsData);
      }
    }, 700);

    return () => clearTimeout(timeOutId);
  }, [value]);

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <TextField {...input('name')} required label={translation.name} />
          <TextField {...input('phone')} error={error?.phone} required label={translation.phone} />
          <Autocomplete
            freeSolo
            options={options}
            onChange={(e, values) => setBranchInitialData(values?.value)}
            filterOptions={(x) => x}
            getOptionLabel={option => option.label}
            onInputChange={(e) => searchPlace(e)}
            inputValue={value}
            renderInput={params => (
              <TextField
                {...params}
                required
                error={error?.address}
                label={translation.address}
                variant="outlined"
                placeholder={translation.fill_the_address}
                value={value}
                fullWidth
              />
            )}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;

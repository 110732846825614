import { useCallback } from "react";
import useStore from "context";
import { useHistory } from "react-router-dom";
import {
  OLD_PASSWORD_ERROR,
  CONFIRMATION_PASSWORD_ERROR,
  OLD_PASSWORD,
  CONFIRMATION_PASSWORD,
  ERROR_MESSAGE,
  FUNCTION,
  SUCCESS_MESSAGE,
} from "Constants";

export const usePutData = (Api, refresh, route = true) => {
  const history = useHistory();
  const { setSuccessMsg, setErrorMsg } = useStore();

  return useCallback(
    async (data, id) => {
      try {
        await Api(data, id);
        if (typeof refresh === FUNCTION) {
          refresh();
        }

        setSuccessMsg(SUCCESS_MESSAGE);
        if (route) history.goBack();
      } catch (e) {
        const { param } = e.content[0];
        if (param === OLD_PASSWORD) {
          setErrorMsg(OLD_PASSWORD_ERROR);
        } else if (param === CONFIRMATION_PASSWORD) {
          setErrorMsg(CONFIRMATION_PASSWORD_ERROR);
        } else {
          setErrorMsg(e.message || ERROR_MESSAGE);
        }
      }
    },
    [Api, refresh, setErrorMsg, setSuccessMsg]
  );
};

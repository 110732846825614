import React from "react";
import {Link, useParams, useLocation} from "react-router-dom";
import {useGetData } from "hooks";
import * as Api from "service";
import {WrapperSeamless} from "layout-components";
import {Avatar, Button, Card, CardContent, Grid, Tooltip} from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ViewIcon from "@material-ui/icons/Visibility";
import Base from "service/base";
import { useTranslation } from 'localization/translation';
import useStore from "context";
import { INVENTORIES, EXPORT_AS, PDF, EXCEL, SEARCH, NO_RESULT } from "Constants";

const View = () => {
    const { inventoryId, setInventoryId } = useStore();
    const translation = useTranslation();
    const {id, did} = useParams();
    const query = new URLSearchParams(useLocation().search);
    const search = query.get(SEARCH);

    const [items] = useGetData(
        Api.inventories.getById, id, search
    );

    const exportExcel = (id) => {
        const api = new Base();
        api.getRequest('/export/excel/' + id).then(res => {
            const downloader = document.getElementById('downloader');
            downloader.href = res.path;
            downloader.click();
        })
    }

    const exportPdf = (id) => {
        const api = new Base();
        api.getRequest('/export/pdf/' + id).then(res => {
            const downloader = document.getElementById('downloader');
            downloader.href = res.path;
            setTimeout(() => {
                downloader.click();
            }, 2000)
        })
    }

    return (
        <WrapperSeamless>
            <Card className="card-box mb-spacing-6-x2">
                <CardContent>
                    <h6 style={{float:"left"}} className="font-weight-bold font-size-lg mb-0 text-black">
                        {INVENTORIES}{did}
                    </h6>
                    <h6 style={{float:"right"}} className="font-weight-bold font-size-lg mb-0 text-black">
                        {EXPORT_AS} <Link onClick={() => exportPdf(id)}>{PDF}</Link> | <Link onClick={() => exportExcel(id)}>{EXCEL}</Link>
                    </h6>
                    <Grid container justifyContent="center">
                        {!!items?.data?.length ?
                            items?.data?.map((item, index) => {
                            return (
                                <Grid className={`mt-3`} key={index} xs={10} md={12} item>
                                    <Accordion 
                                        expanded={item.id === inventoryId} 
                                        onChange={(e, expanded) => {
                                            if(expanded) setInventoryId(item.id)
                                            if(!expanded && inventoryId === item.id) 
                                                setInventoryId(null)
                                        }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>{item?.name}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <table className="table">
                                                <thead className="text-center">
                                                    <th>
                                                        {translation.image}
                                                    </th>
                                                    <th>
                                                        {translation.name}
                                                    </th>
                                                    <th>
                                                        {translation.barcode}
                                                    </th>
                                                    <th>
                                                        {translation.weight_full_g}
                                                    </th>
                                                    <th>
                                                        {translation.weight_empty_g}
                                                    </th>
                                                    <th>
                                                        {translation.density_g_cm}
                                                    </th>
                                                    <th>
                                                        {translation.bottle_volume_milliliter}
                                                    </th>
                                                    <th>
                                                        {translation.left_over_ml}
                                                    </th>
                                                    <th>
                                                        {translation.action}
                                                    </th>
                                                </thead>
                                                <tbody className="text-center">
                                                {item?.goods?.map((good) => {
                                                    return (
                                                        <tr key={good.id}>
                                                            <td className="d-flex justify-content-center" >
                                                                <Avatar className="mr-0" alt={good?.name_en} src={good?.images} />
                                                            </td>
                                                            <td>
                                                                <b>{good?.name_en}</b>
                                                            </td>
                                                            <td>
                                                                {good?.barcode}
                                                            </td>
                                                            <td>
                                                                {good?.weight_full}
                                                            </td>
                                                            <td>
                                                                {good?.weight_empty}
                                                            </td>
                                                            <td>
                                                                {good?.density}
                                                            </td>
                                                            <td>
                                                                {good?.litrage}
                                                            </td>
                                                            <td className={good?.added_after_completed ?
                                                                "blue_status" : good?.status.toLowerCase()
                                                                }
                                                            >
                                                                {good?.total_liters.toFixed(2)}
                                                            </td>
                                                            <td>

                                                                    <Tooltip placement={`top`} title="View details">
                                                                        <Link to={`/dashboard/inventories/${id}/${good.barcode}/${did}`}>
                                                                            <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                                                                                <ViewIcon color="primary" />
                                                                            </Button>
                                                                        </Link>
                                                                    </Tooltip>

                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </table>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>

                            )
                        }) : 
                            search && <p>{NO_RESULT}</p>
                        }
                    </Grid>
                </CardContent>
            </Card>
            <a style={{display: 'none'}} href={`/`} id={`downloader`} target={`_blank`} download>{translation.download}</a>
        </WrapperSeamless>
    )
}

export default View;

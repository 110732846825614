import React, { useCallback } from "react";
import {
  Container,
  TextField,
  Card,
  Box,
  InputAdornment,
} from "@material-ui/core";

import { useText, usePutData } from "hooks";

import * as Api from "service";
import { Save } from "components";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";

import { OLD_PASSWORD, PASSWORD, CONFIRMATION_PASSWORD } from "Constants";

const initForm = {
  old_password: "",
  password: "",
  password_confirmation: "",
};

const Form = () => {
  const updateCallback = usePutData(Api.main.updatePassword);

  const [text, setText, textChange, input] = useText(initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      await updateCallback(text);
      setText(initForm);
    },
    [setText, text, updateCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <TextField
            InputProps={{
              required: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoToneIcon />
                </InputAdornment>
              ),
            }}
            type={PASSWORD}
            {...input(OLD_PASSWORD)}
          />
          <TextField
            InputProps={{
              required: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoToneIcon />
                </InputAdornment>
              ),
            }}
            type={PASSWORD}
            {...input(PASSWORD)}
          />
          <TextField
            InputProps={{
              required: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoToneIcon />
                </InputAdornment>
              ),
            }}
            type={PASSWORD}
            {...input(CONFIRMATION_PASSWORD)}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;

import React, { useEffect, useState } from "react"
import styles from "./styles.module.scss"
import { Link, useHistory } from "react-router-dom"
import { scrollToElement } from "helpers/scrollEvent";
import useStore from "context";
import CONFIG from "config/configURL"
import { ReactComponent as Menu } from "assets/images/Menu.svg";
import { ReactComponent as Close } from "assets/icons/close.svg";
import Avatar from "assets/images/avatar.jpeg"
import * as Api from "service";
import {Button, Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import { useTranslation } from 'localization/translation';
import { HEADER, SECTIONS_NAME } from "Constants";
import logo from "assets/logo.svg";


const Header = ({ display, url }) => {
  const history = useHistory();
  const translation = useTranslation();
  const { user, setUser, language, setLanguage, section, setSection } = useStore();
  const [active, setActive] = useState('');
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const setHeaderLanguage = (lang) => {
    localStorage.setItem('language', lang)
    setLanguage(lang)
  };

  const logOut = async () => {
    await Api.main.logOff();
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    setUser(null);
    setOpenDialog(false);
    history.push('/');
  };

  const close = () => setOpenDialog(false);
  const openLogoutDialog = () => setOpenDialog(true);

  const scrollElement = (item) => {
    const el = document?.getElementById(`${item}`)
    if(el) {
      const top = window.pageYOffset - 71 + el.getBoundingClientRect().top
      const height = el.scrollHeight
      if (window.scrollY > top && window.scrollY < (top + height)) {
        setActive(item)
      }
    }
  };

  const closeMenu = () => {
    setSection('');
    setOpen(false)
  }

  useEffect(() => {
      const handleScrollEvent = () => {
          const elementsList = [
            SECTIONS_NAME.about_us,
            SECTIONS_NAME.how_it_work,
            SECTIONS_NAME.faq,
            SECTIONS_NAME.contact_us
          ];
          elementsList.forEach((item) => {
            scrollElement(item)
          })
      };
       !url && document.addEventListener('scroll', handleScrollEvent);
      return () => !url && document.removeEventListener('scroll', handleScrollEvent);
  },[]);

  const scrollToTop = () => {
    if(history.location.pathname === '/')
      scrollToElement(url, SECTIONS_NAME.about_us, history);
  }

  useEffect(() => {
    if(section && url !== SECTIONS_NAME.homeUrl) {
     scrollToElement(url, section, history);
    }
  }, [section])


  return (
    <>
      <div className={styles.header_desc}>
          <h1 className={styles.header_title} style={{display}}>
            <Link to="/" onClick={scrollToTop} >
              <img src={logo} alt='Logo' className={styles.bar_control_logo} />
            </Link> 
          </h1>
          <div className={styles.right_flex}>
              <span
                onClick={() => {
                  setSection(SECTIONS_NAME.about_us);
                  scrollToElement(url, SECTIONS_NAME.about_us, history);
                }}
                className={`
                   ${styles.header_menu_item}
                   ${active === SECTIONS_NAME.about_us && styles.active }
                `}
              >
                {translation.about_us}
              </span>
              <span
                onClick={() => {
                  setSection(SECTIONS_NAME.how_it_work);
                  scrollToElement(url, SECTIONS_NAME.how_it_work, history)
                }}
                className={`
                   ${styles.header_menu_item} 
                   ${active === SECTIONS_NAME.how_it_work && styles.active }
                `}
              >
                {translation.how_it_work}
              </span>
              <span
                onClick={() => {
                  setSection(SECTIONS_NAME.contact_us);
                  scrollToElement(url, SECTIONS_NAME.contact_us, history);
                }}
                className={`
                   ${styles.header_menu_item} 
                   ${active === SECTIONS_NAME.contact_us && styles.active }
                `}
              >
                {translation.contacts_us}
              </span>
              <span
                onClick={() => {
                  setSection(SECTIONS_NAME.faq);
                  scrollToElement(url, SECTIONS_NAME.faq, history);
                }}
                className={`
                   ${styles.header_menu_item} 
                   ${active === SECTIONS_NAME.faq && styles.active }
                `}
              >
                {translation.faq}
              </span>
              {!user ? (
                <div className={styles.linksWrapper} onClick={() => setSection('')}>
                  <Link to="/sign_in" className={styles.sign_in}>
                    {translation.sign_in}
                  </Link>
                  <Link to="/sign_up" className={styles.sign_up}>
                  {translation.sign_up}
                  </Link>
                </div>
              ) : (
                <div className={styles.avatarDes}>
                  <Link to="/dashboard">
                    {translation.dashboard}
                  </Link>
                  {user.image ? (
                    <img src={`${CONFIG.imageBaseUrl}${user.image}`} alt="User" />
                  ) : (
                    <img src={Avatar} alt="Avatar" />
                  )}
                  <button onClick={openLogoutDialog}>
                    {translation.log_out}
                  </button>
                </div>
              )}
              <div className={styles.select}>
              <select
                value={language}

                onChange={(e) => setHeaderLanguage(e.target.value)}
              >
                <option value="en">{HEADER.english}</option>
                <option value="hy">{HEADER.armenian}</option>
                <option value="ru">{HEADER.russian}</option>
              </select>
              </div>
          </div>
      </div>
      <div className={styles.header_mob}>
        <h1 className={styles.header_title}>
          <Link to="/">
            <img src={logo} alt='Logo' className={styles.bar_control_logo} />
          </Link>
        </h1>
        <div className={styles.burger}>
          {!open && <Menu onClick={() => setOpen(true)} />}
          {open &&  <Close onClick={() => setOpen(false)} />}
          {open && (
            <div className={styles.menu}>
              {!user ? (
                <>
                  <div
                    className={styles.login}
                    onClick={closeMenu}
                  >
                    <Link to="/sign_in" className={styles.sign_in}>
                      {translation.sign_in}
                    </Link>
                    <Link to="/sign_up" className={styles.sign_up}>
                      {translation.sign_up}
                    </Link>
                  </div>
                  <span className={styles.line} />
                </>
                ) : (
                <div className={styles.avatarMob}>
                  {user.image ? (
                    <img src={`${CONFIG.imageBaseUrl}${user.image}`} alt="User" />
                  ) : (
                    <img src={Avatar} alt="Avatar" />
                  )}
                  <p>{user.name}</p>
                  <p>{user.surname}</p>
                  <p>{user.phone}</p>
                  <p>{user.email}</p>
                  <button onClick={openLogoutDialog}>
                    {translation.log_out}
                  </button>
                </div>
              )}
              <span
                onClick={() => {
                  setOpen(false);
                  setSection(SECTIONS_NAME.about_us);
                  scrollToElement(url, SECTIONS_NAME.about_us, history)
                }}
                className={`
                   ${styles.header_menu_item}
                   ${active === SECTIONS_NAME.about_us && styles.active }
                `}
              >
                  {translation.about_us}
              </span>
              <span
                onClick={() => {
                  setOpen(false);
                  setSection(SECTIONS_NAME.how_it_work);
                  scrollToElement(url, SECTIONS_NAME.how_it_work, history);
                }}
                className={`
                       ${styles.header_menu_item} 
                       ${active === SECTIONS_NAME.how_it_work && styles.active }
                    `}
              >
                {translation.how_it_work}
              </span>
              <span
                onClick={() => {
                  setOpen(false);
                  setSection(SECTIONS_NAME.contact_us);
                  scrollToElement(url, SECTIONS_NAME.contact_us, history)
                }}
                className={`
                       ${styles.header_menu_item} 
                       ${active === SECTIONS_NAME.contact_us && styles.active }
                    `}
              >
                  {translation.contacts_us}
              </span>
              <span
                onClick={() => {
                  setOpen(false);
                  setSection(SECTIONS_NAME.faq);
                  scrollToElement(url, SECTIONS_NAME.faq, history)
                }}
                className={`
                       ${styles.header_menu_item} 
                       ${active === SECTIONS_NAME.faq && styles.active }
                    `}
              >
                {translation.faq}
              </span>
              {user && (
                <Link className={styles.dashboardLink} to="/dashboard">
                  {translation.dashboard}
                </Link>
              )}
              <span className={styles.line} />
              <div className={styles.select}>
                <select
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <option value="en">{HEADER.english}</option>
                  <option value="hy">{HEADER.armenian}</option>
                  <option value="ru">{HEADER.russian}</option>
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
      <Dialog
        className={styles.dialog}
        classes={{
          paper: `modal-content rounded-lg w-90`
        }}
        open={openDialog}
        onClose={close}
        aria-labelledby="form-dialog-title">
        <DialogTitle className={styles.dialogContent}>
          {translation.are_you_sure}
        </DialogTitle>
        <DialogActions className={styles.actions}>
          <Button
            onClick={close}
            variant="contained"
            type="button"
            className={styles.cancel}
          >
            {translation.cancel}
          </Button>
          <Button
            onClick={logOut}
            type="submit"
            variant="contained"
            className={styles.confirm}>
            {translation.yes}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Header

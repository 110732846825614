import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import useStore from "../../context";
import * as Api from "../../service";
import {
    Header,
    LandingUpperSection,
    WhyYouWillLoveBarControl,
    AboutTeamAndProcess,
    ManageInventory,
    HelpingBars,
    ToolComponent,
    ParticularPrinciples,
    FAQ,
    // RightPlan,
    Footer,
} from "components"

const LandingPage = () => {
  const imageBaseUrl = process.env.REACT_APP_imagesBaseUrl;
  const { language } = useStore();
  const [mobileSection, setMobileSection] = useState({});
  const [managementSection, setManagementSection] = useState({});

  const getMobile = () => {
    Api.main.getMobileSection()
      .then(({ data }) => {
        setMobileSection(data);
      })
  };

  const getManagement = () => {
    Api.main.getManagementSection()
      .then(({ data }) => {
        setManagementSection(data);
      })
  };

  useEffect(() => {
    getMobile();
    getManagement();
  },[]);

    return (
        <div className={styles.container}>
            <Header />
            <LandingUpperSection />
            <WhyYouWillLoveBarControl />
            <AboutTeamAndProcess />
            <ManageInventory />
            <HelpingBars />
            <ToolComponent
              subTitle={mobileSection?.[`title_${language}`]}
              title={mobileSection?.[`secondary_title_${language}`]}
              description={mobileSection?.[`description_${language}`]}
              image={`${imageBaseUrl}${mobileSection?.image}`}
              id="mobile_app"
            />
            <ToolComponent
              subTitle={managementSection?.[`title_${language}`]}
              title={managementSection?.[`secondary_title_${language}`]}
              description={managementSection?.[`description_${language}`]}
              image={`${imageBaseUrl}${managementSection?.image}`}
              id="management_tool"
              isReverse
            />
            {/*<RightPlan />*/}
            <ParticularPrinciples />
            <FAQ />
            <Footer url='' />
        </div>
    )
}

export default LandingPage

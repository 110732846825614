import React, { useState, useCallback, useEffect } from 'react';
import './style.scss';
import * as Api from '../../service';
import { useTextFront } from '../../hooks';
import Input from '../../components/input-sign/input';
import withHeaderAndFooter from 'components/with-header-and-footer-hoc';
import { useParams, useHistory } from 'react-router';
import { ReactComponent as Loading } from '../../assets/icons/loading.svg';
import { useTranslation } from 'localization/translation';

const initForm = {
    password: '',
    confirmPassword: '',
};

const Invitation = () => {
    const translation = useTranslation();
    const [text, setText, , input] = useTextFront(initForm);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(true);
    const [tokenErrorText, setTokenErrorText] = useState(false);

    const history = useHistory();

    let { token } = useParams();

    useEffect(() => {
        (async () => {
            try {
                setSuccess('');
                setError('');
                const response = await Api.sign.invitationCheck(token)
                if (response.success) {
                    setLoading(false);
                } else {
                    throw new Error('Token is\'nt valid');
                }

            } catch (err) {
                setLoading(false)
                setTokenErrorText('Invalid Token')
                console.log(err);
            }
        })();
    }, []);

    const submit = useCallback(async (e) => {
        e.preventDefault();
        try {
            if (text.password !== text.confirmPassword) {
                setText(initForm);
                return setError('Password confirmation is\nt valid try else');
            }

            setSuccess('');
            setError('');
            await Api.sign.invitationActivation(token, { password: text.password });
            setSuccess('Account activated');
            history.replace("/sign_in");
        } catch (err) {
            setError(err.message);
            console.log(err);
        }
    }, [text]);

    return (
        <section className="sign-section">
            {tokenErrorText && <span className='token_error'> {tokenErrorText}</span>}
            {
                loading ?
                <div className='load_icon'>
                    <Loading />
                </div>
                : !tokenErrorText &&
                <div className="main-container">
                    <h2>{translation.account_activation}</h2>
                    <form onSubmit={submit}>
                        <h3>{translation.enter_password_account}</h3>
                        <div className="sing-by-mail">
                            <Input {...input.password} required type="password" placeholder={translation.password} />
                            <Input {...input.confirmPassword} required type="password" placeholder={translation.repeat_password} />
                            <span className="error">{error}</span>
                            <span className="success">{success}</span>
                            <button type="submit" className="submit-btn">{translation.save}</button>
                        </div>
                    </form>
                </div>
            }
        </section>
    );
};

export default withHeaderAndFooter(Invitation);

import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import TitleSubtitle from 'components/titlie-subtitle';
import styles from './styles.module.scss';
import * as Api from "service";
import useStore from "context";

const ReadyToImprove = () => {
  const { language } = useStore();
  const [imageSection, setImageSection] = useState({});

  useEffect(() => {
    Api.main.getImageSection()
      .then(({ data }) => {
        setImageSection(data)
      })

  },[]);

  useEffect(() => {
    if (imageSection?.image) {
      document.getElementById(
        'image_section'
      ).style.backgroundImage = `url(${process.env.REACT_APP_imagesBaseUrl}${imageSection.image})`
    }
  }, [imageSection])
    return (
        <div className={styles.main_content} id="image_section">
            <TitleSubtitle
                subtitle={imageSection?.[`title_${language}`]}
                text={imageSection?.[`description_${language}`]}
                subtitleClassName={styles.subtitle}
                textClassName={styles.text}
            />
            <Link to={imageSection?.button_link} className={styles.get_started}>
              {imageSection?.[`button_text_${language}`]}
            </Link>
        </div>
    )
}

export default ReadyToImprove

import Resource from './resource';
export const imgUrl = process.env.REACT_APP_imagesBaseUrl;

export { default as main } from './main';
export { default as sign } from './main-front';


export const goods = new Resource('/client/goods');
export const goodsManager = new Resource('/manager/goods');
export const goodsType = new Resource('/client/goods-type');
export const goodsTypeManager = new Resource('/manager/goods-type');
export const branches = new Resource('/client/branches');
export const requestBranches = new Resource('/client/request-branches');
export const managerInvitations = new Resource('/client/manager-invitations');
export const managers = new Resource('/client/managers');
export const barmens = new Resource('/client/barmens');
export const inventories = new Resource('/client/dashboard/inventories');
export const privacy = new Resource('/web/privacy-policy')
export const terms = new Resource('/web/terms-and-conditions');
export const goodsTypeOrder = new Resource('/client/goods-type/order');

import React, { Component } from 'react';
import "./with-header-and-footer-hoc.scss";
import Footer from "../footer";
import Header from 'components/header';
import scroll from 'react-scroll';
import '../../styles/styles-home.scss'

export default function withHeaderAndFooter(WrappedComponent) {

    const scrollToElement = (i) => {
        const getHeight = () => {
            const el = document?.getElementById(`${i}`);
            if (el) {
              const top = el.getBoundingClientRect().top + window.pageYOffset - 140;
              scroll.animateScroll.scrollTo(top, { duration: 500 });
            }
        };
        if (window.innerWidth > 1024) {
            getHeight();
        } else {
            setTimeout(() => {
                getHeight();
            }, 500);
        }
    };
    return class extends Component {
        render() {
            return (
                <div className="page_container">
                    <Header scrollToElement={scrollToElement} />
                    <div className="page_content">
                        <WrappedComponent {...this.props} />
                        <Footer />
                    </div>
                </div>
            );
        };
    };
};

import React, { useCallback } from 'react';
import { Container, TextField, Card, Box, Switch, FormControlLabel } from '@material-ui/core';

import {
  useText,
  useGetData,
  usePutData,
  usePostData, useGetList
} from '../../../hooks';
import useStore from '../../../context';

import * as Api from '../../../service';
import { Save, MultiSelect} from '../../../components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'localization/translation';

const initForm = {
    active: true,
    name: '',
    surname: '',
    phone: '',
    email: '',
    branches: [],
};

const Form = () => {
  const { id } = useParams();
  const translation = useTranslation()
  const [data, , refresh] = useGetData(
      id && Api.barmens.getById,
      id
  );
  const postCallback = usePostData(Api.barmens.create, refresh);
  const updateCallback = usePutData(Api.barmens.update, refresh);
  const { setErrorMsg } = useStore();
  const [ branches ] = useGetList(Api.branches.getAll);

  const [text, setText, , input] = useText(data || initForm);

  const phoneStatus = (e, text) => {
    const { name, value } = e.target
    const phoneReg = /^(?:(\+))?(?:[0-9]{0,3}[\s.\/-]?)?(?:(?:\((?=\d{3}\)))?(\d{3})(?:(?!\(\d{3})\))?[\s.\/-]?)?(?:(?:\((?=\d{3}\)))?(\d{3})(?:(?!\(\d{3})\))?[\s.\/-]?)?(?:(?:\((?=\d{4}\)))?(\d{4})(?:(?!\(\d{4})\))?[\s.\/-]?)?$/.test(value)
    if(text) {
      setText(text => ({
        ...text,
        [name]: phoneReg ? value : value && text[name]
      }))
    }
  }

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const submitForm = useCallback((e) => {
      e.preventDefault();
      !validateEmail(text.email) ? setErrorMsg("Invalid email") :
      id ? updateCallback(text) : postCallback(text);
    },[id, postCallback, setErrorMsg, text, updateCallback]
  );

  return (
      <Container>
        <Card className="shadow-xxl px-4 py-2">
          <Box component="form" onSubmit={submitForm}>
          <TextField {...input('name')} label={translation.first_name} required />
          <TextField {...input('surname')} label={translation.last_name} required />
          <TextField {...input('phone')} label={translation.phone} required onChange={(e) => phoneStatus(e, text)} />
          <TextField {...input('email')} label={translation.email} required disabled={id} />
          <div className="my-3 py-3">
              <FormControlLabel
                  style={{marginLeft: 0}}
                  control={<Switch
                      checked={text.active}
                      onClick={()=>setText((st) => ({...st, active: !st.active}))}
                      className="switch-medium"
                      color="primary"
                      name="checkedB"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                  />}
                  label={<span style={{marginLeft: '10px'}}>user active state</span>}
              />
          </div>
            {
              branches && <>
                <MultiSelect
                    noCard
                    className='w-100'
                    value={text.branches}
                    onChange={val => setText(st => ({ ...st, branches: val }))}
                    data={branches}
                    label={translation.select_branch}
                    field="name"
                />
              </>
            }
            <Save />
          </Box>
        </Card>
      </Container>
  );
};

export default Form;

import React, { useCallback, useState } from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import Input from "components/login-input";
import { useTextFront } from "hooks";
import * as Api from "service";
import { Checkbox, Header } from "components";
import { ReactComponent as CheckRight } from "assets/images/checkRight.svg";
import { isValidForm, isValidField } from "helpers/formValidation";
import { useTranslation } from "localization/translation";
import { TEXT, EMAIL, PHONE, NUMBER, ROUTES, VALIDATION_ERRORS, PHONE_ERROR_PARAM, HEADER_URLS } from "Constants";

const initForm = {
  company_name: "",
  name: "",
  surname: "",
  business_address: "",
  phone_number: "",
  tax_code: "",
  email: "",
  reg_number: "",
  branch_count: "",
};

const validation = {
  company_name: "string:required",
  name: "string:required",
  surname: "string:required",
  business_address: "string:required",
  phone_number: "phone:required",
  tax_code: "string:required",
  email: "email:required",
  reg_number: "string",
  branch_count: "number:required",
};

const SignUp = () => {
  const translation = useTranslation();
  const [text, , , input] = useTextFront(initForm);
  const [error, setError] = useState("");
  const [fieldError, setFieldError] = useState({});
  const [isCheck, setIsCheck] = useState(false);
  const [signUp, setSignUp] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const submit = useCallback(
    async (e) => {
      setIsSubmitted(true);
      e.preventDefault();
      if (isValidForm({ text, validation, setFieldError })) {
        try {
          setError("");
          await Api.sign.sign_up(text).then(() => setSignUp(true));
          setFieldError({});
        } catch (err) {
          setError(err.message);
          if(err.content[0].param === PHONE_ERROR_PARAM) {
            setFieldError((prev) => ({
              ...prev,
              phone_number: VALIDATION_ERRORS.phone,
            }))
          }
        }
      }
    },
    [text]
  );

  return (
    <section className={styles.signSection}>
      <Header url={HEADER_URLS.sign_up} />
      {signUp ? (
        <div className={styles.mainContainer}>
          <h2>{translation.thank_you_register}</h2>
          <div className={styles.successAlert}>
            <CheckRight />
            <p>{translation.successfully_sign_up}</p>
          </div>
        </div>
      ) : (
        <div className={styles.mainContainer}>
          <h2>{translation.sign_up}</h2>
          <p>
            {translation.sign_up_subtitle}
            <br />
            {translation.can}{" "}
            <Link to={ROUTES.sign_in}>{translation.sign_in_here}</Link>
          </p>

          <form onSubmit={submit}>
            <div className={styles.singByMail}>
              <Input
                {...input.name}
                error={
                  isSubmitted &&
                  isValidField({ value: text.name, required: true })
                }
                helpText={
                  isValidField({
                    value: text.name,
                    required: true,
                  }) && fieldError?.name
                }
                type={TEXT}
                placeholder={translation.first_name}
                autoComplete="off"
              />
              <Input
                {...input.surname}
                error={
                  isSubmitted &&
                  isValidField({
                    value: text.surname,
                    required: true,
                  })
                }
                helpText={
                  isValidField({
                    value: text.surname,
                    required: true,
                  }) && fieldError?.surname
                }
                type={TEXT}
                placeholder={translation.last_name}
                autoComplete="off"
              />
              <Input
                {...input.company_name}
                error={
                  isSubmitted &&
                  isValidField({
                    value: text.company_name,
                    required: true,
                  })
                }
                helpText={
                  isValidField({
                    value: text.company_name,
                    required: true,
                  }) && fieldError?.company_name
                }
                type={TEXT}
                placeholder={translation.company_name}
              />
              <Input
                {...input.business_address}
                error={
                  isSubmitted &&
                  isValidField({
                    value: text.business_address,
                    required: true,
                  })
                }
                helpText={
                  isValidField({
                    value: text.business_address,
                    required: true,
                  }) && fieldError?.business_address
                }
                type={TEXT}
                placeholder={translation.company_business_address}
              />
              <Input
                {...input.branch_count}
                error={
                  isSubmitted &&
                  isValidField({
                    value: text.branch_count,
                    type: NUMBER,
                    required: true,
                  })
                }
                helpText={
                  isValidField({
                    value: text.branch_count,
                    type: NUMBER,
                    required: true,
                  }) && fieldError?.branch_count
                }
                type={NUMBER}
                placeholder={translation.branch_count}
              />
              <Input
                {...input.email}
                error={
                  isSubmitted &&
                  isValidField({
                    value: text.email,
                    type: EMAIL,
                    required: true,
                  })
                }
                helpText={
                  isValidField({
                    value: text.email,
                    type: EMAIL,
                    required: true,
                  }) && fieldError?.email
                }
                placeholder={translation.company_email}
              />
              <Input
                {...input.phone_number}
                error={
                  isSubmitted &&
                  isValidField({
                    value: text.phone_number,
                    type: PHONE,
                    required: true,
                  })
                }
                helpText={
                  isValidField({
                    value: text.phone_number,
                    type: PHONE,
                    required: true,
                  }) && fieldError?.phone_number
                }
                type={TEXT}
                placeholder={translation.phone_number}
              />
              <Input
                {...input.tax_code}
                error={
                  isSubmitted &&
                  isValidField({
                    value: text.tax_code,
                    required: true,
                  })
                }
                helpText={
                  isValidField({
                    value: text.tax_code,
                    required: true,
                  }) && fieldError?.tax_code
                }
                type={TEXT}
                placeholder={translation.tax_code}
              />
              <Input
                {...input.reg_number}
                helpText={
                  isValidField({
                    value: text.reg_number,
                    required: true,
                  }) && fieldError?.reg_number
                }
                type={TEXT}
                placeholder={translation.registration_number}
              />
              <div className={styles.checkboxFiled}>
                <Checkbox
                  checked={isCheck}
                  onChange={() => setIsCheck((prev) => !prev)}
                />
                <p className={styles.checkboxLabel}>
                  {translation.agree}{" "}
                  <Link to={ROUTES.terms} target='_blank' className={styles.terms_and_privacy}>
                    {translation.terms}
                  </Link>{" "}
                  {translation.and}{" "}
                  <Link to={ROUTES.privacy} target='_blank' className={styles.terms_and_privacy}>
                    {translation.privacy_policy.toLowerCase()}
                  </Link>
                </p>
              </div>
              <span className={styles.error}>{error}</span>
              <button
                disabled={!isCheck}
                type="submit"
                className="submit-btn"
                style={{ opacity: !isCheck && 0.5 }}
              >
                {translation.sign_up}
              </button>
            </div>
          </form>
        </div>
      )}
    </section>
  );
};

export default SignUp;

import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as WhiteAppStoreLogo } from "assets/images/AppStoreWhite.svg"
import { ReactComponent as WhiteGooglePlayLogo } from "assets/images/GooglePlayWhite.svg"
import { scrollToElement } from "../../helpers/scrollEvent";
import useStore from "../../context";
import * as Api from "../../service";
import { useTranslation } from 'localization/translation';

const icons = [1,2,3,4,5];

const image_url = process.env.REACT_APP_imagesBaseUrl;

const Footer = ({ url }) => {
  const history = useHistory();
  const { language } = useStore();
  const translation = useTranslation();
  const [footerSection, setFooterSection] = useState([]);

  const getFAQ = () => {
    Api.main.getFooterSection()
      .then(({ data }) => {
        setFooterSection(data);
      })
  };

  useEffect(() => {
    getFAQ();
  },[]);

  return (
    <div className={styles.container}>
      <div className={styles.navigate}>
        <div className={styles.social}>
          <a href={footerSection?.app_store}>
            <WhiteAppStoreLogo />
          </a>
          <a href={footerSection?.play_market}>
            <WhiteGooglePlayLogo />
          </a>
        </div>
        <div className={styles.navigateSection}>
          <h4>{translation.about}</h4>
          <span onClick={() => scrollToElement(url, 'about_us', history)}>
            {translation.about_us}
          </span>
          <Link to="/terms">{translation.terms_of_use}</Link>
          <Link to="/privacy">{translation.privacy_policy}</Link>
        </div>
        <div className={styles.navigateSection}>
          <h4>{translation.product}</h4>
          <span onClick={() => scrollToElement(url, 'how_it_work', history)}>
            {translation.how_it_works}
          </span>
          <span onClick={() => scrollToElement(url, 'mobile_app', history)}>
            {translation.mobile_app}
            </span>
          <span onClick={() => scrollToElement(url, 'management_tool', history)}>
            {translation.management_tool}
          </span>
        </div>
        <div className={styles.navigateSection}>
          <h4>
            {footerSection?.[`title_${language}`]}
          </h4>
          {footerSection?.email && (
            <a href={`mailto:${footerSection?.email}`}>{footerSection?.email}</a>
          )}
          {footerSection?.phone_number && (
            <a href={`tel:${footerSection?.phone_number}`}>{footerSection?.phone_number}</a>
          )}
          <div className={styles.socialIcons}>
            {icons.map((i) => {
              if (footerSection?.[`social${i}_link`]) {
                return (
                  <a href={footerSection?.[`social${i}_link`]}>
                    <img src={image_url+"/"+footerSection?.[`social${i}_icon`]} />
                  </a>
                )
              }
            })}
          </div>
        </div>
      </div>
      <div className={styles.copyright}>
        <p>Copyright © 2022 Bar Control Inc.</p>
      </div>
    </div>
  )
};
export default Footer;

export const CURRENT_TO_NEXT_LANG = {
  hy: "ru",
  ru: "en",
  en: "hy",
};

export const GOOD_TYPE = "goodType";
export const MANAGER_ROLE = "manager";

export const TEXT = "text";
export const EMAIL = "email";
export const PHONE = "phone";
export const NUMBER = "number";
export const PASSWORD = "password";
export const NEW_PASSWORD = "new-password";
export const STRING = "string";
export const REQUIRED = "required";

export const SUCCESS_EMAIL = "Email was sent";

export const VALIDATION_ERRORS = {
  phone: "Phone Number is not valid",
  email: "Email is not valid",
  number: "Number is not valid",
  required: "Required",
}

export const ROUTES = {
  dashboard: "/dashboard",
  sign_up: "/sign_up",
  forget_password: "/forget_password",
  sign_in: "/sign_in",
  terms: "/terms",
  privacy: "/privacy",
}

export const TOKEN = "token";

export const ADD_ONE_TO_ORDER = 1;
export const DRAG_ALT = "Drag";
export const ORDER = "order";

export const SCROLL_DOWN = 1;
export const SCROLL_UP = -1;

export const OLD_PASSWORD = "old_password";
export const CONFIRMATION_PASSWORD = "password_confirmation";
export const OLD_PASSWORD_ERROR = "Wrong old password";
export const CONFIRMATION_PASSWORD_ERROR = "Invalid confirmation password";

export const ERROR_MESSAGE = "There was an error";

export const FUNCTION = "function";
export const SUCCESS_MESSAGE = "Updated";

export const NAME_EN="name_en";
export const NAME_HY="name_hy";
export const NAME_RU="name_ru";

export const REMAINING_BRANCHES="Remaining branches";
export const EMPTY_ARRAY_LENGTH = 0;

export const CHOOSE_US_SUCCESS_MSG = "Your message has been sent successfully";

export const INVENTORIES = "Inventory #";
export const EXPORT_AS = "export as";
export const PDF = "PDF";
export const EXCEL = "Excel";

export const HEADER_URLS = {
  sign_in: "/sign_in",
  sign_up: "/sign_up",
  forget_password: "/forget_password",
  reset_password: "/reset_password",
  verify_email: "/verify/email/:token",
  new_password: "/new-password/:token",
  register: "/register/continue/:token",
}

export const PHONE_ERROR_PARAM = "phone_number";
export const ACTIVE_STATUS_VALUE = 1;
export const INACTIVE_STATUS_VALUE = 0;
export const ACTIVE_STATUS = "activate";
export const INACTIVE_STATUS = "deactivate";
export const DELETE = 'delete';
export const EDIT = "edit";
export const ACTION = 'Action';
export const CREATE = "create";
export const SEARCH = "search";
export const LANGUAGE = 'language';
export const PHONE_FIELD_NAME = "phone_number";

export const BUTTON_VARIANTS = {
  contained: "contained",
}

export const NO_RESULT = "No result";

export const SECTIONS_NAME = {
  about_us: 'about_us',
  how_it_work: 'how_it_work',
  faq: 'faq',
  contact_us: 'contact_us',
  homeUrl: 'home'
}

export const HEADER = {
  english: 'Eng',
  russian: 'Ru',
  armenian: 'Hy',
}

export const INVENTORY_DATE_FORMAT = 'DD/MM/YYYY HH:mm';
export const BEFORE = "Before:";
export const AFTER = "After:";
export const HISTORY_SCALES_TITLE = "History of scales";
export const EMPTY_WEIGHT = 0;
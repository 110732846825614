import React, { useState, createContext, useContext, useEffect } from 'react';
import {useLocation} from "react-router-dom";

export const StoreContext = createContext();

export const Store = (props) => {
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [remove, setRemove] = useState(null);
  const [user, setUser] = useState(null);
  const [lang, setLang] = useState('en');
  const [section, setSection] = useState('');
  const [language, setLanguage] = useState(localStorage.getItem("language") || 'en');
  const [inventoryId, setInventoryId] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if(!location.pathname.includes("/dashboard/inventories/"))
      setInventoryId(null);
  }, [location.pathname])

  return (

    <StoreContext.Provider
      value={{
        successMsg,
        setSuccessMsg,
        errorMsg,
        setErrorMsg,
        remove,
        setRemove,
        user,
        setUser,
        lang,
        setLang,
        section,
        setSection,
        language,
        setLanguage,
        inventoryId,
        setInventoryId
      }}>
      {props.children}
    </StoreContext.Provider>
  );
};



export default () => useContext(StoreContext);

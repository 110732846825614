import React from "react"
import { Link } from "react-router-dom"
import styles from "./styles.module.scss"

const TitleSubtitle = ({ title, subtitle, text, subtitleClassName, textClassName }) => {
    return (
        <div className={styles.main_section}>
            {title && <Link to="/landing" className={styles.title}>{title}</Link>}
            <h1 className={subtitleClassName}>{subtitle}</h1>
            {text && <p className={textClassName}>{text}</p>}
        </div>
    )
}

export default TitleSubtitle

import React, { useCallback, useState } from 'react';
import styles from './styles.module.scss';
import Input from 'components/login-input';
import { useTextFront } from 'hooks';
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { ReactComponent as BackArrow} from 'assets/images/backArrow.svg';
import { useTranslation } from 'localization/translation';
import Header from 'components/header';
import { HEADER_URLS } from 'Constants';


const initForm = {
  password: '',
  repeat_password: ''
};

const SetPassword = ({ title, subTitle, buttonText, request }) => {
  const params = useParams();
  const history = useHistory();
  const [text, setText, , input] = useTextFront(initForm);
  const [error, setError] = useState('');
  const translation = useTranslation();

  const submit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const data = {
        password: text.password,
      }
      setError('');
      await request({token: params?.token, data });
      setText(initForm)
      history.push("/sign_in")
    } catch (err) {
      setError(err.message);
      console.log(err);
    }
  }, [text]);


  const isDisabled = !(!!text.password && text.password === text.repeat_password)

  return (
    <section className={styles.signSection}>
      <Header url={HEADER_URLS.reset_password} />
      <div className={styles.mainContainer}>
        <div className={styles.titleSection}>
          <BackArrow onClick={() => history.goBack()} />
          <h2>{title}</h2>
        </div>
        <p>
          {subTitle}
        </p>
          <form
            onSubmit={submit} >
            <div className={styles.singByMail}>
              <Input {...input.password} required type="password" placeholder={translation.password} />
              <Input {...input.repeat_password} required type="password" placeholder={translation.repeat_password} />
              <button
                type="submit"
                className="submit-btn"
                disabled={isDisabled}
                style={{opacity: isDisabled && 0.5 }}>
                {buttonText}
              </button>
              <span className={styles.error}>{error}</span>
            </div>
          </form>
      </div>
    </section>
  );
};

export default SetPassword;

const MENU = {
    "Branch management": [
        {
            "name": "Branches",
            "link": "/dashboard/branches"
        },
        {
            "name": "Request for new branch",
            "link": "/dashboard/request-branches"
        }
    ],
    "Manager invitations": "/dashboard/manager-invitations",
    "Managers": "/dashboard/managers",
    "Barmen list": "/dashboard/barmen",
    "Goods type": "/dashboard/goods-type",
    "Goods": "/dashboard/goods",
    "Inventories": "/dashboard/inventories"
}

export default MENU

import { useState, useCallback } from 'react';
import { PHONE_FIELD_NAME } from 'Constants';

export const useTextFront = (initialText) => {
  const [text, setText] = useState(initialText);

  const textChange = useCallback((e) => {
    const { name, value } = e.target;

    const validPhoneNumber = /^\+?\d*$/;
    const isPhoneValid = 
      name === PHONE_FIELD_NAME && !validPhoneNumber.test(value);

    setText((text) => ({ 
      ...text, 
      [name]: isPhoneValid ? value.slice(0, -1) : value 
    }));

  }, []);

  return [text, setText, textChange, {
    ...Object.keys(initialText).reduce((a, b) => ({
      ...a,
      [b]: {
        name: b,
        value: text[b],
        onChange: textChange,
      },
    }), {}),
  }];
};
